export const light = {
  success: '#09B462',
  black: '#2D3436',
  black1: '#2F3542',
  mainColor: '#00CEC9',
  gray: '#B2BEC3',
  gray1: '#ECEEEE',
  grayText: '#636E72',
  borderGray: '#DFE6E9',
  mint: '#F4FCFF',
  violet: '#6C5CE7',
  error: '#FF7675',
  errorWithOpacity30: '#ffeae9',
  beige: '#F5F5F5',
  red: '#FF7675',
  highlightTableRow: '#effaff',
  hoverTableRowBg: '#F8FDFF',

  background: '#FFFFFF',
  bigCircle: '#F4FCFF',
  blackWhite: '#2D3436',
  whiteBlack: '#FFFFFF',
  grayBlack: '#d6d6d6',
  grayWithOpacity30: '#f5f5f5',
  grayWithOpacity50: '#B2BEC3',
  copyIcon: '#6C5CE7',
  whiteRed: '#fff',
  redWhite: '#D9433C',

  tableBG: '#FFF',
  sidebar: '#FFF',
  selector: '#FFF',
  selectorHover: '#f5f5f5',

  filterItemBG: '#e6e5e5',
  filterItemColor: '#666e71',

  whiteGray: '#FFF',
  grayWhite: '#B2BEC3',
  yellowBG: '#fffee9',

  mmButton: '#F5F5F5',

  // UI V2
  v2Border: '#E6E5E5',
  v2primary: '#705ceb',
  primary: '#705ceb',
  v2third: '#D4CEF9',
  v2forth: '#F4F2FD',
  v2primaryLight: '#F4F2FD',
  v2secondary: '#f4f2fd',
  textColor1: '#656e71',
  v2PrimaryRed: '#DD5A55',
  v2SecondaryRed: '#FEF5F4',
  v2redBackgound: '#fff4f4',
  v2darkGrey: '#74747F',

  v2Green: '#17B18C',

  v2chips: '#f5f5f5',
  ipWrapperBorder: '#f5f5f5',

  riskCircle1: '#17B18C',
  riskCircle2: '#F0B057',
  riskCircle3: '#D9433C',

  selectedMenuItemBackground: '#f4f2fd',
  selectedMenuItemColor: '#705ceb',
  selectedProjectList: '#f4f2fd',

  btn4background: '#fff',
  btn4color: '#2D3436',
  btn4border: '#B2BEC3',

  rightSidePopup: '#fff',

  borderInput: '#EAEAEE',

  btn2background: '#F4F2FD',
  btn2color: '#705ceb',

  greyPrimary: '#f5f5f5',
  darkerCopyBG: '#d0c8ff',

  formLabel: '#666E71',
  expandedRowBGColor: '#f5f5f5',

  menuActiveProjectHover: '#f5f5f5',
  menuActiveProjectIconHover: '#E0E0E0',
  warning: '#ffb648',
  highlightedBorder: '#B4BEC2',

  pending: '#D9A757',
  outstanding: '#4C9FDC',
  refunding: '#7B4397',
  problematic: '#D9433C',
};

export const dark = {
  success: '#09B462',
  black: '#2D3436',
  black1: '#2F3542',
  mainColor: '#00CEC9',
  gray: '#B2BEC3',
  gray1: '#ECEEEE',
  grayText: '#fff',
  borderGray: '#DFE6E9',
  mint: '#2e2e2e',
  violet: '#6C5CE7',
  error: '#FF7675',
  errorWithOpacity30: '#ffeae9',
  beige: '#111',
  red: '#643331',
  highlightTableRow: '#000',

  background: '#2D3436',
  bigCircle: '#161616',
  blackWhite: '#FFFFFF',
  whiteBlack: '#2D3436',
  grayBlack: '#000',
  grayWithOpacity30: '#414141',
  grayWithOpacity50: '#626262',
  copyIcon: '#888888',
  whiteRed: '#171A1C',
  redWhite: '#D9433C',

  tableBG: '#1E1E1E',
  sidebar: '#1e1e1e',
  selector: '#171A1C',
  selectorHover: '#323337',

  hoverTableRowBg: '#333',
  filterItemBG: '#666e71',
  filterItemColor: '#e6e5e5',

  whiteGray: '#B2BEC3',
  grayWhite: '#fff',
  yellowBG: '#464646',

  mmButton: '#6c6c6c',

  // UI V2
  v2Border: '#2E2A3B',
  v2primary: '#705ceb',
  primary: '#705ceb',
  v2third: '#705ceb',
  v2forth: '#181818',
  v2primaryLight: '#272727',
  v2secondary: '#a895ff',
  textColor1: '#656e71',
  v2PrimaryRed: '#DD5A55',
  v2SecondaryRed: '#960f00',
  v2redBackgound: '#fff4f4',
  v2darkGrey: '#aeb6b9',

  v2Green: '#17B18C',

  v2chips: '#505357',

  riskCircle1: '#17B18C',
  riskCircle2: '#F0B057',
  riskCircle3: '#D9433C',

  selectedMenuItemBackground: '#262238',
  selectedMenuItemColor: '#705ceb',
  selectedProjectList: '#fff',

  btn4background: '#705ceb',
  btn4color: '#fff',
  btn4border: '#705ceb',

  rightSidePopup: '#171A1C',

  borderInput: '#74747F',

  btn2background: '#705ceb',
  btn2color: '#fff',

  greyPrimary: '#705ceb',
  darkerCopyBG: '#483e7e',

  formLabel: '#aeb6b9',
  expandedRowBGColor: '#222',

  ipWrapperBorder: '#9D9FA2',

  // menuActiveProjectHover: '#f5f5f5',
  // menuActiveProjectIconHover: '#E0E0E0',
  warning: '#ffb648',
  highlightedBorder: '#B4BEC2',

  pending: '#D9A757',
  outstanding: '#4C9FDC',
  refunding: '#7B4397',
  problematic: '#D9433C',
};
