import currenciesIcons from '@/helpers/currenciesIcons';

export default {
  name: 'Activator',
  props: [
    'activatorData',
    'rounded',
    'pressed',
    'upperCase',
    'textTransformNone',
    'icon',
    'multipleFilter',
    'activatorClass',
    'hideCount',
  ],
  computed: {
    icons: () => currenciesIcons,
  },
};
