const icons: { [x: string]: string } = {
  '1INCH': 'currencies/1INCH.svg',
  '2KEY': 'currencies/2key.svg',
  AAVE: 'currencies/AAVE.svg',
  ABBC: 'currencies/ABBC.svg',
  ADA: 'currencies/ADA.svg',
  ADX: 'currencies/ADX.svg',
  AE: 'currencies/AE.svg',
  AED: 'currencies/AED.svg',
  AERGO: 'currencies/AERGO.svg',
  AFN: 'currencies/AFN.svg',
  AGIX: 'currencies/AGIX.svg',
  AION: 'currencies/AION.svg',
  AKRO: 'currencies/AKRO.svg',
  AKT: 'currencies/AKT.svg',
  ALGO: 'currencies/ALGO.svg',
  ALICE: 'currencies/ALICE.svg',
  ALL: 'currencies/ALL.svg',
  ALPHA: 'currencies/ALPHA.svg',
  ALT: 'currencies/ALT.svg',
  AMD: 'currencies/AMD.svg',
  AMP: 'currencies/AMP.svg',
  AMPL: 'currencies/AMPL.svg',
  ANC: 'currencies/ANC.svg',
  ANG: 'currencies/ANG.svg',
  ANKR: 'currencies/ANKR.svg',
  ANT: 'currencies/ANT.svg',
  APE: 'currencies/APE.svg',
  APL: 'currencies/APL.svg',
  APT: 'currencies/APT.svg',
  AR: 'currencies/AR.svg',
  ARB: 'currencies/ARB.svg',
  ARDR: 'currencies/ARDR.svg',
  ARK: 'currencies/ARK.svg',
  ARPA: 'currencies/ARPA.svg',
  ARRR: 'currencies/ARRR.svg',
  ARS: 'currencies/ARS.svg',
  ASTA: 'currencies/ASTA.svg',
  ATOM: 'currencies/ATOM.svg',
  ATRI: 'currencies/ATRI.svg',
  AUCTION: 'currencies/AUCTION.svg',
  AUD: 'currencies/AUD.svg',
  AUDIO: 'currencies/AUDIO.svg',
  AUTO: 'currencies/AUTO.svg',
  AVA: 'currencies/AVA.svg',
  AVAX: 'currencies/AVAX.svg',
  AVAXC: 'currencies/AVAXC.svg',
  AWG: 'currencies/AWG.svg',
  AXEL: 'currencies/AXEL.svg',
  AXS: 'currencies/AXS.svg',
  AZN: 'currencies/AZN.svg',
  ABT: 'currencies/Abt.svg',
  ACT: 'currencies/Act.svg',
  ADK: 'currencies/Adk.svg',
  AEON: 'currencies/Aeon.svg',
  AGRS: 'currencies/Agrs.svg',
  AOA: 'currencies/Aoa.svg',
  APPC: 'currencies/Appc.svg',
  ARNX: 'currencies/Arnx.svg',
  ASAFE: 'currencies/Asafe.svg',
  AST: 'currencies/Ast.svg',
  BAKE: 'currencies/BAKE.svg',
  BAL: 'currencies/BAL.svg',
  BAM: 'currencies/BAM.svg',
  BAND: 'currencies/BAND.svg',
  BAR: 'currencies/BAR.svg',
  BASIC: 'currencies/BASIC.svg',
  BAT: 'currencies/BAT.svg',
  BBD: 'currencies/BBD.svg',
  BCD: 'currencies/BCD.svg',
  BCH: 'currencies/BCH.svg',
  BCHA: 'currencies/BCHA.svg',
  BCN: 'currencies/BCN.svg',
  BDT: 'currencies/BDT.svg',
  BEAM: 'currencies/BEAM.svg',
  BELT: 'currencies/BELT.svg',
  BEST: 'currencies/BEST.svg',
  BGN: 'currencies/BGN.svg',
  BHD: 'currencies/BHD.svg',
  BIF: 'currencies/BIF.svg',
  BIFI: 'currencies/BIFI.svg',
  BLZ: 'currencies/BLZ.svg',
  BMD: 'currencies/BMD.svg',
  BMX: 'currencies/BMX.svg',
  BNANA: 'currencies/BNANA.svg',
  BNB: 'currencies/BNB.svg',
  BNBBSC: 'currencies/BNBBSC.svg',
  BNB_BSC: 'currencies/BNB_BSC.svg',
  BND: 'currencies/BND.svg',
  BNT: 'currencies/BNT.svg',
  BOB: 'currencies/BOB.svg',
  BOND: 'currencies/BOND.svg',
  BORA: 'currencies/BORA.svg',
  BOTX: 'currencies/BOTX.svg',
  BRL: 'currencies/BRL.svg',
  BRZ: 'currencies/BRZ.svg',
  BSV: 'currencies/BSV.svg',
  BTC: 'currencies/BTC.svg',
  BTCB: 'currencies/BTCB.svg',
  BTCST: 'currencies/BTCST.svg',
  BTG: 'currencies/BTG.svg',
  BTM: 'currencies/BTM.svg',
  BTMX: 'currencies/BTMX.svg',
  BTN: 'currencies/BTN.svg',
  BTS: 'currencies/BTS.svg',
  BTT: 'currencies/BTT.svg',
  BTU: 'currencies/BTU.svg',
  BURGER: 'currencies/BURGER.svg',
  BUSD: 'currencies/BUSD.svg',
  BUSDBS: 'currencies/BUSDBS.svg',
  BWP: 'currencies/BWP.svg',
  BYN: 'currencies/BYN.svg',
  BYR: 'currencies/BYR.svg',
  BZD: 'currencies/BZD.svg',
  BZRX: 'currencies/BZRX.svg',
  BELA: 'currencies/Bela.svg',
  BF: 'currencies/Bf.svg',
  BIX: 'currencies/Bix.svg',
  BLOCK: 'currencies/Block.svg',
  BMC: 'currencies/Bmc.svg',
  BNK: 'currencies/Bnk.svg',
  BNTY: 'currencies/Bnty.svg',
  BOS: 'currencies/Bos.svg',
  BSD: 'currencies/Bsd.svg',
  BST: 'currencies/Bst.svg',
  BTCP: 'currencies/Btcp.svg',
  BTCZ: 'currencies/Btcz.svg',
  BTO: 'currencies/Bto.svg',
  BTX: 'currencies/Btx.svg',
  BURST: 'currencies/Burst.svg',
  CAD: 'currencies/CAD.svg',
  CAKE: 'currencies/CAKE.svg',
  CCXX: 'currencies/CCXX.svg',
  CDF: 'currencies/CDF.svg',
  CEL: 'currencies/CEL.svg',
  CELO: 'currencies/CELO.svg',
  CELR: 'currencies/CELR.svg',
  CENNZ: 'currencies/CENNZ.svg',
  CET: 'currencies/CET.svg',
  CFX: 'currencies/CFX.svg',
  CHF: 'currencies/CHF.svg',
  CHR: 'currencies/CHR.svg',
  CHSB: 'currencies/CHSB.svg',
  CHZ: 'currencies/CHZ.svg',
  CKB: 'currencies/CKB.svg',
  CLF: 'currencies/CLF.svg',
  CLP: 'currencies/CLP.svg',
  CNY: 'currencies/CNY.svg',
  COMP: 'currencies/COMP.svg',
  COP: 'currencies/COP.svg',
  COTI: 'currencies/COTI.svg',
  CRC: 'currencies/CRC.svg',
  CRE: 'currencies/CRE.svg',
  CREAM: 'currencies/CREAM.svg',
  CRO: 'currencies/CRO.svg',
  CRU: 'currencies/CRU.svg',
  CRV: 'currencies/CRV.svg',
  CSPR: 'currencies/CSPR.svg',
  CTC: 'currencies/CTC.svg',
  CTK: 'currencies/CTK.svg',
  CTSI: 'currencies/CTSI.svg',
  CUC: 'currencies/CUC.svg',
  CUP: 'currencies/CUP.svg',
  CUSD: 'currencies/CUSD.svg',
  CVC: 'currencies/CVC.svg',
  CVE: 'currencies/CVE.svg',
  CXO: 'currencies/CXO.svg',
  CZK: 'currencies/CZK.svg',
  CCE: 'currencies/Cce.svg',
  CDAI: 'currencies/Cdai.svg',
  CETH: 'currencies/Ceth.svg',
  CHAT: 'currencies/Chat.svg',
  CLAM: 'currencies/Clam.svg',
  CLOAK: 'currencies/Cloak.svg',
  CMM: 'currencies/Cmm.svg',
  CMT: 'currencies/Cmt.svg',
  CND: 'currencies/Cnd.svg',
  COB: 'currencies/Cob.svg',
  COLX: 'currencies/Colx.svg',
  CRD: 'currencies/Crd.svg',
  CRPT: 'currencies/Crpt.svg',
  CRW: 'currencies/Crw.svg',
  CS: 'currencies/Cs.svg',
  CTXC: 'currencies/Ctxc.svg',
  CUBE: 'currencies/Cube.svg',
  CUSDC: 'currencies/Cusdc.svg',
  CUSDT: 'currencies/Cusdt.svg',
  CVT: 'currencies/Cvt.svg',
  DAG: 'currencies/DAG.svg',
  DAI: 'currencies/DAI.svg',
  DAO: 'currencies/DAO.svg',
  DASH: 'currencies/DASH.svg',
  DATA: 'currencies/DATA.svg',
  DAWN: 'currencies/DAWN.svg',
  DCR: 'currencies/DCR.svg',
  DDX: 'currencies/DDX.svg',
  DEGO: 'currencies/DEGO.svg',
  DENT: 'currencies/DENT.svg',
  DFI: 'currencies/DFI.svg',
  DGB: 'currencies/DGB.svg',
  DGD: 'currencies/DGD.svg',
  DIA: 'currencies/DIA.svg',
  DIVI: 'currencies/DIVI.svg',
  DJF: 'currencies/DJF.svg',
  DKK: 'currencies/DKK.svg',
  DNT: 'currencies/DNT.svg',
  DOCK: 'currencies/DOCK.svg',
  DODO: 'currencies/DODO.svg',
  DOGE: 'currencies/DOGE.svg',
  DOP: 'currencies/DOP.svg',
  DOT: 'currencies/DOT.svg',
  DRS: 'currencies/DRS.svg',
  DUSK: 'currencies/DUSK.svg',
  DVI: 'currencies/DVI.svg',
  DVPN: 'currencies/DVPN.svg',
  DZD: 'currencies/DZD.svg',
  DAT: 'currencies/Dat.svg',
  DBC: 'currencies/Dbc.svg',
  DCN: 'currencies/Dcn.svg',
  DLT: 'currencies/Dlt.svg',
  DMT: 'currencies/Dmt.svg',
  DRC: 'currencies/Drc.svg',
  DRG: 'currencies/Drg.svg',
  DTH: 'currencies/Dth.svg',
  EGLD: 'currencies/EGLD.svg',
  EGP: 'currencies/EGP.svg',
  EGR: 'currencies/EGR.svg',
  ELA: 'currencies/ELA.svg',
  ELF: 'currencies/ELF.svg',
  ENJ: 'currencies/ENJ.svg',
  EOS: 'currencies/EOS.svg',
  ERG: 'currencies/ERG.svg',
  ERN: 'currencies/ERN.svg',
  ESD: 'currencies/ESD.svg',
  ETB: 'currencies/ETB.svg',
  ETC: 'currencies/ETC.svg',
  ETH: 'currencies/ETH.svg',
  ETN: 'currencies/ETN.svg',
  EUM: 'currencies/EUM.svg',
  EUR: 'currencies/EUR.svg',
  EUROC: 'currencies/EUROC.svg',
  EURS: 'currencies/EURS.svg',
  EWT: 'currencies/EWT.svg',
  EXRD: 'currencies/EXRD.svg',
  EBST: 'currencies/Ebst.svg',
  ECA: 'currencies/Eca.svg',
  EDG: 'currencies/Edg.svg',
  EGT: 'currencies/Egt.svg',
  EKT: 'currencies/Ekt.svg',
  ELLA: 'currencies/Ella.svg',
  EMC: 'currencies/Emc.svg',
  EMC2: 'currencies/Emc2.svg',
  ENG: 'currencies/Eng.svg',
  ESBC: 'currencies/Esbc.svg',
  ETP: 'currencies/Etp.svg',
  ETZ: 'currencies/Etz.svg',
  EVX: 'currencies/Evx.svg',
  EXP: 'currencies/Exp.svg',
  FEI: 'currencies/FEI.svg',
  FET: 'currencies/FET.svg',
  FIDA: 'currencies/FIDA.svg',
  FIL: 'currencies/FIL.svg',
  FIO: 'currencies/FIO.svg',
  FIRO: 'currencies/FIRO.svg',
  FJD: 'currencies/FJD.svg',
  FKP: 'currencies/FKP.svg',
  FLM: 'currencies/FLM.svg',
  FLOW: 'currencies/FLOW.svg',
  FORTH: 'currencies/FORTH.svg',
  FRAX: 'currencies/FRAX.svg',
  FTM: 'currencies/FTM.svg',
  FTN: 'currencies/FTN.svg',
  FTT: 'currencies/FTT.svg',
  FUN: 'currencies/FUN.svg',
  FX: 'currencies/FX.svg',
  FXS: 'currencies/FXS.svg',
  FACE: 'currencies/Face.svg',
  FCT: 'currencies/Fct.svg',
  FJC: 'currencies/Fjc.svg',
  FSN: 'currencies/Fsn.svg',
  FTC: 'currencies/Ftc.svg',
  FUEL: 'currencies/Fuel.svg',
  GAL: 'currencies/GAL.svg',
  GALA: 'currencies/GALA.svg',
  GAS: 'currencies/GAS.svg',
  GBP: 'currencies/GBP.svg',
  GEL: 'currencies/GEL.svg',
  GGP: 'currencies/GGP.svg',
  GHS: 'currencies/GHS.svg',
  GHST: 'currencies/GHST.svg',
  GIP: 'currencies/GIP.svg',
  GLM: 'currencies/GLM.svg',
  GMD: 'currencies/GMD.svg',
  GMX: 'currencies/GMX.svg',
  GNF: 'currencies/GNF.svg',
  GNO: 'currencies/GNO.svg',
  GRS: 'currencies/GRS.svg',
  GRT: 'currencies/GRT.svg',
  GT: 'currencies/GT.svg',
  GTC: 'currencies/GTC.svg',
  GTQ: 'currencies/GTQ.svg',
  GUSD: 'currencies/GUSD.svg',
  GYD: 'currencies/GYD.svg',
  GAME: 'currencies/Game.svg',
  GBX: 'currencies/Gbx.svg',
  GIN: 'currencies/Gin.svg',
  GLS: 'currencies/Gls.svg',
  GRC: 'currencies/Grc.svg',
  GRIN: 'currencies/Grin.svg',
  GSC: 'currencies/Gsc.svg',
  GVT: 'currencies/Gvt.svg',
  GXC: 'currencies/Gxc.svg',
  HAI: 'currencies/HAI.svg',
  HBAR: 'currencies/HBAR.svg',
  HBTC: 'currencies/HBTC.svg',
  HEDG: 'currencies/HEDG.svg',
  HEX: 'currencies/HEX.svg',
  HIVE: 'currencies/HIVE.svg',
  HKD: 'currencies/HKD.svg',
  HNL: 'currencies/HNL.svg',
  HNS: 'currencies/HNS.svg',
  HNT: 'currencies/HNT.svg',
  HOGE: 'currencies/HOGE.svg',
  HOT: 'currencies/HOT.svg',
  HRK: 'currencies/HRK.svg',
  HT: 'currencies/HT.svg',
  HTG: 'currencies/HTG.svg',
  HTR: 'currencies/HTR.svg',
  HUF: 'currencies/HUF.svg',
  HUM: 'currencies/HUM.svg',
  HUSD: 'currencies/HUSD.svg',
  HXRO: 'currencies/HXRO.svg',
  HAKKA: 'currencies/Hakka.svg',
  HC: 'currencies/Hc.svg',
  HPP: 'currencies/Hpp.svg',
  HTML: 'currencies/Html.svg',
  HUSH: 'currencies/Hush.svg',
  HYDRO: 'currencies/Hydro.svg',
  ICP: 'currencies/ICP.svg',
  ICX: 'currencies/ICX.svg',
  IDR: 'currencies/IDR.svg',
  IHF: 'currencies/IHF.svg',
  ILS: 'currencies/ILS.svg',
  IMP: 'currencies/IMP.svg',
  IMX: 'currencies/IMX.svg',
  INJ: 'currencies/INJ.svg',
  INO: 'currencies/INO.svg',
  INR: 'currencies/INR.svg',
  IOST: 'currencies/IOST.svg',
  IOTX: 'currencies/IOTX.svg',
  IQ: 'currencies/IQ.svg',
  IQD: 'currencies/IQD.svg',
  IRIS: 'currencies/IRIS.svg',
  IRR: 'currencies/IRR.svg',
  ISK: 'currencies/ISK.svg',
  IDEX: 'currencies/Idex.svg',
  IGNIS: 'currencies/Ignis.svg',
  INB: 'currencies/Inb.svg',
  INK: 'currencies/Ink.svg',
  ION: 'currencies/Ion.svg',
  IOP: 'currencies/Iop.svg',
  IQN: 'currencies/Iqn.svg',
  ITC: 'currencies/Itc.svg',
  JEP: 'currencies/JEP.svg',
  JMD: 'currencies/JMD.svg',
  JOD: 'currencies/JOD.svg',
  JPY: 'currencies/JPY.svg',
  JST: 'currencies/JST.svg',
  KAI: 'currencies/KAI.svg',
  KARMA: 'currencies/KARMA.svg',
  KAVA: 'currencies/KAVA.svg',
  KCS: 'currencies/KCS.svg',
  KEEP: 'currencies/KEEP.svg',
  KES: 'currencies/KES.svg',
  KEY: 'currencies/KEY.svg',
  KGS: 'currencies/KGS.svg',
  KHR: 'currencies/KHR.svg',
  KIN: 'currencies/KIN.svg',
  KLAY: 'currencies/KLAY.svg',
  KLV: 'currencies/KLV.svg',
  KMD: 'currencies/KMD.svg',
  KMF: 'currencies/KMF.svg',
  KNC: 'currencies/KNC.svg',
  KOK: 'currencies/KOK.svg',
  KPW: 'currencies/KPW.svg',
  KRW: 'currencies/KRW.svg',
  KSM: 'currencies/KSM.svg',
  KSP: 'currencies/KSP.svg',
  KWD: 'currencies/KWD.svg',
  KYD: 'currencies/KYD.svg',
  KZT: 'currencies/KZT.svg',
  KAT: 'currencies/Kat.svg',
  KNDC: 'currencies/Kndc.svg',
  KRB: 'currencies/Krb.svg',
  LAK: 'currencies/LAK.svg',
  LBP: 'currencies/LBP.svg',
  LDO: 'currencies/LDO.svg',
  LEO: 'currencies/LEO.svg',
  LINA: 'currencies/LINA.svg',
  LINK: 'currencies/LINK.svg',
  LKR: 'currencies/LKR.svg',
  LON: 'currencies/LON.svg',
  LOOM: 'currencies/LOOM.svg',
  LPT: 'currencies/LPT.svg',
  LQTY: 'currencies/LQTY.svg',
  LRC: 'currencies/LRC.svg',
  LRD: 'currencies/LRD.svg',
  LSK: 'currencies/LSK.svg',
  LSL: 'currencies/LSL.svg',
  LTC: 'currencies/LTC.svg',
  LTL: 'currencies/LTL.svg',
  LTO: 'currencies/LTO.svg',
  LUNA: 'currencies/LUNA.svg',
  LUSD: 'currencies/LUSD.svg',
  LVL: 'currencies/LVL.svg',
  LYD: 'currencies/LYD.svg',
  LA: 'currencies/La.svg',
  LBC: 'currencies/Lbc.svg',
  LIKE: 'currencies/Like.svg',
  LKK: 'currencies/Lkk.svg',
  LRG: 'currencies/Lrg.svg',
  LUN: 'currencies/Lun.svg',
  LYM: 'currencies/Lym.svg',
  MAD: 'currencies/MAD.svg',
  MAID: 'currencies/MAID.svg',
  MANA: 'currencies/MANA.svg',
  MASK: 'currencies/MASK.svg',
  MASS: 'currencies/MASS.svg',
  MATH: 'currencies/MATH.svg',
  MATIC: 'currencies/MATIC.svg',
  MATICP: 'currencies/MATICP.svg',
  MBL: 'currencies/MBL.svg',
  MCO: 'currencies/MCO.svg',
  MDL: 'currencies/MDL.svg',
  MDX: 'currencies/MDX.svg',
  MED: 'currencies/MED.svg',
  MET: 'currencies/MET.svg',
  META: 'currencies/META.svg',
  MFT: 'currencies/MFT.svg',
  MGA: 'currencies/MGA.svg',
  MINA: 'currencies/MINA.svg',
  MIOTA: 'currencies/MIOTA.svg',
  MIR: 'currencies/MIR.svg',
  MITH: 'currencies/MITH.svg',
  MKD: 'currencies/MKD.svg',
  MKR: 'currencies/MKR.svg',
  MLK: 'currencies/MLK.svg',
  MLN: 'currencies/MLN.svg',
  MMK: 'currencies/MMK.svg',
  MNT: 'currencies/MNT.svg',
  MOF: 'currencies/MOF.svg',
  MONA: 'currencies/MONA.svg',
  MOP: 'currencies/MOP.svg',
  MRO: 'currencies/MRO.svg',
  MRU: 'currencies/MRU.svg',
  MTL: 'currencies/MTL.svg',
  MUR: 'currencies/MUR.svg',
  MVL: 'currencies/MVL.svg',
  MVR: 'currencies/MVR.svg',
  MWC: 'currencies/MWC.svg',
  MWK: 'currencies/MWK.svg',
  MX: 'currencies/MX.svg',
  MXC: 'currencies/MXC.svg',
  MXN: 'currencies/MXN.svg',
  MYR: 'currencies/MYR.svg',
  MZN: 'currencies/MZN.svg',
  MDA: 'currencies/Mda.svg',
  MDS: 'currencies/Mds.svg',
  MEETONE: 'currencies/Meetone.svg',
  MNS: 'currencies/Mns.svg',
  MOAC: 'currencies/Moac.svg',
  MPH: 'currencies/Mph.svg',
  MSR: 'currencies/Msr.svg',
  MTH: 'currencies/Mth.svg',
  MYST: 'currencies/Myst.svg',
  NAD: 'currencies/NAD.svg',
  NANO: 'currencies/NANO.svg',
  NEAR: 'currencies/NEAR.svg',
  NEO: 'currencies/NEO.svg',
  NEXO: 'currencies/NEXO.svg',
  NFT: 'currencies/NFT.svg',
  NFTX: 'currencies/NFTX.svg',
  NGN: 'currencies/NGN.svg',
  NIM: 'currencies/NIM.svg',
  NIO: 'currencies/NIO.svg',
  NKN: 'currencies/NKN.svg',
  NMR: 'currencies/NMR.svg',
  NOIA: 'currencies/NOIA.svg',
  NOK: 'currencies/NOK.svg',
  NPR: 'currencies/NPR.svg',
  NRG: 'currencies/NRG.svg',
  NU: 'currencies/NU.svg',
  NULS: 'currencies/NULS.svg',
  NWC: 'currencies/NWC.svg',
  NXM: 'currencies/NXM.svg',
  NYE: 'currencies/NYE.svg',
  NZD: 'currencies/NZD.svg',
  NAME719: 'currencies/Name719.svg',
  NAME747: 'currencies/Name747.svg',
  NAS: 'currencies/Nas.svg',
  NAV: 'currencies/Nav.svg',
  NCASH: 'currencies/Ncash.svg',
  NCT: 'currencies/Nct.svg',
  NEBL: 'currencies/Nebl.svg',
  NEST: 'currencies/Nest.svg',
  NEU: 'currencies/Neu.svg',
  NEW: 'currencies/New.svg',
  NGC: 'currencies/Ngc.svg',
  NLC2: 'currencies/Nlc2.svg',
  NLG: 'currencies/Nlg.svg',
  NMC: 'currencies/Nmc.svg',
  NXT: 'currencies/Nxt.svg',
  OCEAN: 'currencies/OCEAN.svg',
  OGN: 'currencies/OGN.svg',
  OKB: 'currencies/OKB.svg',
  OM: 'currencies/OM.svg',
  OMG: 'currencies/OMG.svg',
  OMR: 'currencies/OMR.svg',
  ONE: 'currencies/ONE.svg',
  ONG: 'currencies/ONG.svg',
  ONT: 'currencies/ONT.svg',
  OP: 'currencies/OP.svg',
  ORBS: 'currencies/ORBS.svg',
  ORC: 'currencies/ORC.svg',
  OXEN: 'currencies/OXEN.svg',
  OXT: 'currencies/OXT.svg',
  OXY: 'currencies/OXY.svg',
  OAX: 'currencies/Oax.svg',
  ODE: 'currencies/Ode.svg',
  OK: 'currencies/Ok.svg',
  OMNI: 'currencies/Omni.svg',
  OOT: 'currencies/Oot.svg',
  OURO: 'currencies/Ouro.svg',
  PAB: 'currencies/PAB.svg',
  PAC: 'currencies/PAC.svg',
  PAX: 'currencies/PAX.svg',
  PAXG: 'currencies/PAXG.svg',
  PEN: 'currencies/PEN.svg',
  PERP: 'currencies/PERP.svg',
  PGK: 'currencies/PGK.svg',
  PHA: 'currencies/PHA.svg',
  PHP: 'currencies/PHP.svg',
  PIVX: 'currencies/PIVX.svg',
  PKR: 'currencies/PKR.svg',
  PLC: 'currencies/PLC.svg',
  PLN: 'currencies/PLN.svg',
  PLTC: 'currencies/PLTC.svg',
  PNK: 'currencies/PNK.svg',
  POL: 'currencies/POL.svg',
  POLS: 'currencies/POLS.svg',
  POLY: 'currencies/POLY.svg',
  POND: 'currencies/POND.svg',
  POWR: 'currencies/POWR.svg',
  PPT: 'currencies/PPT.svg',
  PROM: 'currencies/PROM.svg',
  PRQ: 'currencies/PRQ.svg',
  PUNDIX: 'currencies/PUNDIX.svg',
  PYG: 'currencies/PYG.svg',
  PYR: 'currencies/PYR.svg',
  PART: 'currencies/Part.svg',
  PASC: 'currencies/Pasc.svg',
  PAY: 'currencies/Pay.svg',
  PAYX: 'currencies/Payx.svg',
  PBTC: 'currencies/Pbtc.svg',
  PCX: 'currencies/Pcx.svg',
  PEARL: 'currencies/Pearl.svg',
  PERL: 'currencies/Perl.svg',
  PINK: 'currencies/Pink.svg',
  PIRL: 'currencies/Pirl.svg',
  PLBT: 'currencies/Plbt.svg',
  PLR: 'currencies/Plr.svg',
  PLT: 'currencies/Plt.svg',
  PLU: 'currencies/Plu.svg',
  PMA: 'currencies/Pma.svg',
  PNT: 'currencies/Pnt.svg',
  POE: 'currencies/Poe.svg',
  POLIS: 'currencies/Polis.svg',
  POT: 'currencies/Pot.svg',
  PPC: 'currencies/Ppc.svg',
  PPP: 'currencies/Ppp.svg',
  PRE: 'currencies/Pre.svg',
  PRO: 'currencies/Pro.svg',
  PTOY: 'currencies/Ptoy.svg',
  QAR: 'currencies/QAR.svg',
  QKC: 'currencies/QKC.svg',
  QNT: 'currencies/QNT.svg',
  QQQ: 'currencies/QQQ.svg',
  QTUM: 'currencies/QTUM.svg',
  QASH: 'currencies/Qash.svg',
  QLC: 'currencies/Qlc.svg',
  QRL: 'currencies/Qrl.svg',
  QSP: 'currencies/Qsp.svg',
  QUN: 'currencies/Qun.svg',
  RAMP: 'currencies/RAMP.svg',
  RARI: 'currencies/RARI.svg',
  RAY: 'currencies/RAY.svg',
  RBTC: 'currencies/RBTC.svg',
  RDD: 'currencies/RDD.svg',
  REEF: 'currencies/REEF.svg',
  REN: 'currencies/REN.svg',
  RENBTC: 'currencies/RENBTC.svg',
  REP: 'currencies/REP.svg',
  REQ: 'currencies/REQ.svg',
  REV: 'currencies/REV.svg',
  REVV: 'currencies/REVV.svg',
  RFOX: 'currencies/RFOX.svg',
  RFR: 'currencies/RFR.svg',
  RIF: 'currencies/RIF.svg',
  RLC: 'currencies/RLC.svg',
  RLY: 'currencies/RLY.svg',
  RON: 'currencies/RON.svg',
  ROSE: 'currencies/ROSE.svg',
  RSD: 'currencies/RSD.svg',
  RSR: 'currencies/RSR.svg',
  RUB: 'currencies/RUB.svg',
  RUNE: 'currencies/RUNE.svg',
  RWF: 'currencies/RWF.svg',
  RCN: 'currencies/Rcn.svg',
  RDN: 'currencies/Rdn.svg',
  RISE: 'currencies/Rise.svg',
  RSV: 'currencies/Rsv.svg',
  RUFF: 'currencies/Ruff.svg',
  RVN: 'currencies/Rvn.svg',
  RYO: 'currencies/Ryo.svg',
  SAFEMOON: 'currencies/SAFEMOON.svg',
  SAND: 'currencies/SAND.svg',
  SAPP: 'currencies/SAPP.svg',
  SAR: 'currencies/SAR.svg',
  SBD: 'currencies/SBD.svg',
  SC: 'currencies/SC.svg',
  SCR: 'currencies/SCR.svg',
  SCRT: 'currencies/SCRT.svg',
  SDG: 'currencies/SDG.svg',
  SEK: 'currencies/SEK.svg',
  SFP: 'currencies/SFP.svg',
  SGD: 'currencies/SGD.svg',
  SHFT: 'currencies/SHFT.svg',
  SHIB: 'currencies/SHIB.svg',
  SHP: 'currencies/SHP.svg',
  SHR: 'currencies/SHR.svg',
  SKL: 'currencies/SKL.svg',
  SLL: 'currencies/SLL.svg',
  SLP: 'currencies/SLP.svg',
  SNACK: 'currencies/SNACK.svg',
  SNT: 'currencies/SNT.svg',
  SNX: 'currencies/SNX.svg',
  SOL: 'currencies/SOL.svg',
  SOLO: 'currencies/SOLO.svg',
  SOS: 'currencies/SOS.svg',
  SRD: 'currencies/SRD.svg',
  SRM: 'currencies/SRM.svg',
  STAKE: 'currencies/STAKE.svg',
  STD: 'currencies/STD.svg',
  STEEM: 'currencies/STEEM.svg',
  STETH: 'currencies/STETH.svg',
  STMX: 'currencies/STMX.svg',
  STN: 'currencies/STN.svg',
  STORJ: 'currencies/STORJ.svg',
  STRAX: 'currencies/STRAX.svg',
  STRK: 'currencies/STRK.svg',
  STRONG: 'currencies/STRONG.svg',
  STX: 'currencies/STX.svg',
  SUI: 'currencies/SUI.svg',
  SUPER: 'currencies/SUPER.svg',
  SUSD: 'currencies/SUSD.svg',
  SUSHI: 'currencies/SUSHI.svg',
  SVC: 'currencies/SVC.svg',
  SWAP: 'currencies/SWAP.svg',
  SXP: 'currencies/SXP.svg',
  SYP: 'currencies/SYP.svg',
  SYS: 'currencies/SYS.svg',
  SZL: 'currencies/SZL.svg',
  SALT: 'currencies/Salt.svg',
  SAN: 'currencies/San.svg',
  SEELE: 'currencies/Seele.svg',
  SHIFT: 'currencies/Shift.svg',
  SHIP: 'currencies/Ship.svg',
  SHROOM: 'currencies/Shroom.svg',
  SIB: 'currencies/Sib.svg',
  SKY: 'currencies/Sky.svg',
  SLR: 'currencies/Slr.svg',
  SLS: 'currencies/Sls.svg',
  SMART: 'currencies/Smart.svg',
  SNGLS: 'currencies/Sngls.svg',
  SNM: 'currencies/Snm.svg',
  SNOW: 'currencies/Snow.svg',
  SOC: 'currencies/Soc.svg',
  SOUL: 'currencies/Soul.svg',
  SPANK: 'currencies/Spank.svg',
  SPHTX: 'currencies/Sphtx.svg',
  SRN: 'currencies/Srn.svg',
  STAK: 'currencies/Stak.svg',
  START: 'currencies/Start.svg',
  STQ: 'currencies/Stq.svg',
  SUB: 'currencies/Sub.svg',
  SUMO: 'currencies/Sumo.svg',
  SUTER: 'currencies/Suter.svg',
  SWTH: 'currencies/Swth.svg',
  TEL: 'currencies/TEL.svg',
  TFUEL: 'currencies/TFUEL.svg',
  THB: 'currencies/THB.svg',
  THETA: 'currencies/THETA.svg',
  TIA: 'currencies/TIA.svg',
  TITAN: 'currencies/TITAN.svg',
  TJS: 'currencies/TJS.svg',
  TKO: 'currencies/TKO.svg',
  TLM: 'currencies/TLM.svg',
  TLOS: 'currencies/TLOS.svg',
  TMT: 'currencies/TMT.svg',
  TND: 'currencies/TND.svg',
  TOMO: 'currencies/TOMO.svg',
  TON: 'currencies/TON.svg',
  TOP: 'currencies/TOP.svg',
  TRAC: 'currencies/TRAC.svg',
  TRB: 'currencies/TRB.svg',
  TRIBE: 'currencies/TRIBE.svg',
  TRX: 'currencies/TRX.svg',
  TRX_USDC: 'currencies/TRX_USDC.svg',
  TRY: 'currencies/TRY.svg',
  TT: 'currencies/TT.svg',
  TTD: 'currencies/TTD.svg',
  TTT: 'currencies/TTT.svg',
  TUSD: 'currencies/TUSD.svg',
  TVK: 'currencies/TVK.svg',
  TWD: 'currencies/TWD.svg',
  TWT: 'currencies/TWT.svg',
  TZS: 'currencies/TZS.svg',
  TAU: 'currencies/Tau.svg',
  TBX: 'currencies/Tbx.svg',
  TCH: 'currencies/Tch.svg',
  TEN: 'currencies/Ten.svg',
  TERA: 'currencies/Tera.svg',
  TERN: 'currencies/Tern.svg',
  THR: 'currencies/Thr.svg',
  TIX: 'currencies/Tix.svg',
  TKN: 'currencies/Tkn.svg',
  TKS: 'currencies/Tks.svg',
  TNB: 'currencies/Tnb.svg',
  TNC: 'currencies/Tnc.svg',
  TNT: 'currencies/Tnt.svg',
  TOR: 'currencies/Tor.svg',
  TORN: 'currencies/Torn.svg',
  TPAY: 'currencies/Tpay.svg',
  TRTL: 'currencies/Trtl.svg',
  TZC: 'currencies/Tzc.svg',
  UAH: 'currencies/UAH.svg',
  UBT: 'currencies/UBT.svg',
  UGX: 'currencies/UGX.svg',
  ULT: 'currencies/ULT.svg',
  UMA: 'currencies/UMA.svg',
  UNI: 'currencies/UNI.svg',
  UOS: 'currencies/UOS.svg',
  UQC: 'currencies/UQC.svg',
  USD: 'currencies/USD.svg',
  USDC: 'currencies/USDC.svg',
  USDCBSC: 'currencies/USDCBSC.svg',
  USDCP: 'currencies/USDCP.svg',
  USDCSOL: 'currencies/USDCSOL.svg',
  USDC_BSC: 'currencies/USDC_BSC.svg',
  USDN: 'currencies/USDN.svg',
  USDT: 'currencies/USDT.svg',
  USDTBSC: 'currencies/USDTBSC.svg',
  USDTSOL: 'currencies/USDTSOL.svg',
  USDTTR: 'currencies/USDTTR.svg',
  USDT_BSC: 'currencies/USDT_BSC.svg',
  UST: 'currencies/UST.svg',
  UTK: 'currencies/UTK.svg',
  UYU: 'currencies/UYU.svg',
  UZS: 'currencies/UZS.svg',
  UBQ: 'currencies/Ubq.svg',
  VAI: 'currencies/VAI.svg',
  VEE: 'currencies/VEE.svg',
  VEF: 'currencies/VEF.svg',
  VERI: 'currencies/VERI.svg',
  VES: 'currencies/VES.svg',
  VET: 'currencies/VET.svg',
  VGX: 'currencies/VGX.svg',
  VLX: 'currencies/VLX.svg',
  VND: 'currencies/VND.svg',
  VRA: 'currencies/VRA.svg',
  VRSC: 'currencies/VRSC.svg',
  VRT: 'currencies/VRT.svg',
  VSYS: 'currencies/VSYS.svg',
  VTHO: 'currencies/VTHO.svg',
  VUV: 'currencies/VUV.svg',
  VEIL: 'currencies/Veil.svg',
  VIA: 'currencies/Via.svg',
  VIBE: 'currencies/Vibe.svg',
  VITE: 'currencies/Vite.svg',
  VRC: 'currencies/Vrc.svg',
  VTC: 'currencies/Vtc.svg',
  WAN: 'currencies/WAN.svg',
  WAVES: 'currencies/WAVES.svg',
  WAXP: 'currencies/WAXP.svg',
  WBNB: 'currencies/WBNB.svg',
  WBTC: 'currencies/WBTC.svg',
  WICC: 'currencies/WICC.svg',
  WIN: 'currencies/WIN.svg',
  WLD: 'currencies/WLD.svg',
  WNXM: 'currencies/WNXM.svg',
  WOO: 'currencies/WOO.svg',
  WOZX: 'currencies/WOZX.svg',
  WRX: 'currencies/WRX.svg',
  WST: 'currencies/WST.svg',
  WTC: 'currencies/WTC.svg',
  WGRO: 'currencies/Wgro.svg',
  WING: 'currencies/Wing.svg',
  WINGS: 'currencies/Wings.svg',
  WPR: 'currencies/Wpr.svg',
  XAF: 'currencies/XAF.svg',
  XAG: 'currencies/XAG.svg',
  XAU: 'currencies/XAU.svg',
  XCD: 'currencies/XCD.svg',
  XDB: 'currencies/XDB.svg',
  XDC: 'currencies/XDC.svg',
  XDR: 'currencies/XDR.svg',
  XEM: 'currencies/XEM.svg',
  XHV: 'currencies/XHV.svg',
  XLM: 'currencies/XLM.svg',
  XMR: 'currencies/XMR.svg',
  XNC: 'currencies/XNC.svg',
  XOF: 'currencies/XOF.svg',
  XOR: 'currencies/XOR.svg',
  XPD: 'currencies/XPD.svg',
  XPF: 'currencies/XPF.svg',
  XPRT: 'currencies/XPRT.svg',
  XPT: 'currencies/XPT.svg',
  XRP: 'currencies/XRP.svg',
  XTZ: 'currencies/XTZ.svg',
  XVG: 'currencies/XVG.svg',
  XVS: 'currencies/XVS.svg',
  XWC: 'currencies/XWC.svg',
  XYM: 'currencies/XYM.svg',
  XYO: 'currencies/XYO.svg',
  XAS: 'currencies/Xas.svg',
  XBC: 'currencies/Xbc.svg',
  XBY: 'currencies/Xby.svg',
  XCM: 'currencies/Xcm.svg',
  XCP: 'currencies/Xcp.svg',
  XDN: 'currencies/Xdn.svg',
  XIN: 'currencies/Xin.svg',
  XMX: 'currencies/Xmx.svg',
  XMY: 'currencies/Xmy.svg',
  XPA: 'currencies/Xpa.svg',
  XPM: 'currencies/Xpm.svg',
  XPR: 'currencies/Xpr.svg',
  XSR: 'currencies/Xsr.svg',
  XUC: 'currencies/Xuc.svg',
  YER: 'currencies/YER.svg',
  YFI: 'currencies/YFI.svg',
  YOUC: 'currencies/YOUC.svg',
  YOYOW: 'currencies/Yoyow.svg',
  ZAR: 'currencies/ZAR.svg',
  ZB: 'currencies/ZB.svg',
  ZEC: 'currencies/ZEC.svg',
  ZEN: 'currencies/ZEN.svg',
  ZEON: 'currencies/ZEON.svg',
  ZETA: 'currencies/ZETA.svg',
  ZIL: 'currencies/ZIL.svg',
  ZKS: 'currencies/ZKS.svg',
  ZLW: 'currencies/ZLW.svg',
  ZMK: 'currencies/ZMK.svg',
  ZMW: 'currencies/ZMW.svg',
  ZNN: 'currencies/ZNN.svg',
  ZRX: 'currencies/ZRX.svg',
  ZWL: 'currencies/ZWL.svg',
  ZCL: 'currencies/Zcl.svg',
  ZEL: 'currencies/Zel.svg',
  AETH: 'currencies/aEth.svg',
  BTCB_BSC: 'currencies/btcb-bsc.svg',
  ERSDL: 'currencies/eRSDL.svg',
  MAAPL: 'currencies/mAAPL.svg',
  USDC_ERC: 'currencies/usdc-erc.svg',
  USDC_TRX: 'currencies/usdc-trx.svg',
  USDT_BNB: 'currencies/usdt-bnb.svg',
  USDT_ERC: 'currencies/usdt-erc.svg',
  USDT_TRX: 'currencies/usdt-trx.svg',
  USDTE: 'currencies/usdte.svg',
  USDTT: 'currencies/usdtt.svg',
  VBNB: 'currencies/vBNB.svg',
};

Object.entries(icons).forEach(([key, value]) => {
  icons[key] = value;
  icons[`${key}_TEST`] = value;

  for (const x of Array(1000).keys()) {
    icons[`${key}_${x}`] = value;
    icons[`${key}_TEST${x}`] = value;
    icons[`${key}_TEST_${x}`] = value;
  }
});

export default icons;
