<template>
  <svg
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.26172 7.42188H17.2617M1.26172 7.42188V16.2221C1.26172 17.3422 1.26172 17.9019 1.47971 18.3298C1.67145 18.7061 1.97719 19.0124 2.35352 19.2041C2.78092 19.4219 3.34071 19.4219 4.45863 19.4219H14.0648C15.1827 19.4219 15.7417 19.4219 16.1691 19.2041C16.5454 19.0124 16.8522 18.7061 17.0439 18.3298C17.2617 17.9024 17.2617 17.3434 17.2617 16.2255V7.42188M1.26172 7.42188V6.62207C1.26172 5.50197 1.26172 4.9415 1.47971 4.51367C1.67145 4.13735 1.97719 3.83161 2.35352 3.63986C2.78134 3.42188 3.34181 3.42188 4.46191 3.42188H5.26172M17.2617 7.42188V6.61878C17.2617 5.50087 17.2617 4.94108 17.0439 4.51367C16.8522 4.13735 16.5454 3.83161 16.1691 3.63986C15.7413 3.42188 15.182 3.42188 14.0619 3.42188H13.2617M13.2617 1.42188V3.42188M13.2617 3.42188H5.26172M5.26172 1.42188V3.42188"
      stroke="#666E71"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Calendar',
  };
</script>

<style lang="scss" scoped>
  .a {
    stroke: currentColor;
  }
</style>
