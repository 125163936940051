/* eslint-disable indent */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-this-alias */
import Vue from 'vue';
import moment from 'moment/moment';
import { Framework } from 'vuetify/types';
import store from '@/store';
import TextHelper from '@/helpers/textHelper';
import notify from '@/helpers/notifyHelper';
import Logo from '@/assets/images/favicon-2.svg';
import {
  RoleTypeAction,
  checkAccess,
  checkMerchant,
} from '@/helpers/roleCheckHelper';
import StorageHelper from '@/helpers/localStorageHelper';

const fiatList = [
  'USD',
  'EUR',
  'AUD',
  'JPY',
  'GBP',
  'RUB',
  'UAH',
  'BRL',
  'CAD',
  'BYN',
  'NZD',
  'TRY',
  'CZK',
  'HUF',
  'INR',
  'ARS',
  'MXN',
  'NOK',
];

const stablecoinsList = [
  'USDC',
  'USDCE',
  'USDCT',
  'USDT',
  'USDTE',
  'USDTT',
  'DAI',
  'BUSD',
  'TUSD',
  'FRAX',
  'USDP',
  'LUSD',
  'USDT_BSC',
  'TRX_USDC',
  'ETH_USDC',
  'ALGO_USDC',
  'XLM_USDC',
  'ALGO_USDC_2V6G',
  'TRX_USDC_SKL',
  'TRX_USDC_SKL5',
  'XLM_USDC_T_CEKS',
];

const currenciesList = (list: string[]) =>
  [...new Set(list)].reduce((result: string[], coin: string) => {
    result.push(coin);
    result.push(`${coin}_TEST`);
    result.push(`${coin}_TEST3`);

    return result;
  }, []);

// let appEl: any = null;

// Mouse handler
const handleMouseMove =
  (el: any, bindToCurrent = false) =>
  ({ clientX, clientY }: MouseEvent) => {
    if (!el) return;

    if (bindToCurrent) {
      el.style.top = `${clientY}px`;
      el.style.left = `${clientX}px`;
    } else {
      el.querySelector('.tooltip-text').style.top = `${clientY}px`;
      el.querySelector('.tooltip-text').style.left = `${clientX}px`;
    }
  };

const trauncateFractionAndFormat = (parts: any, digits: any) =>
  parts
    .map(({ type, value }: any) => {
      if (type !== 'fraction' || !value || value?.length < digits) {
        return value;
      }

      let retVal = '';
      for (
        let idx = 0, counter = 0;
        idx < value?.length && counter < digits;
        idx++
      ) {
        counter++;
        retVal += value[idx];
      }
      return retVal;
    })
    .reduce((string: string, part: any) => string + part);

// TODO: Move to properly space
declare module 'vue/types/options' {
  interface ComponentOptions<V extends Vue> {
    beforeUnmount?(): void;
    unmounted?(): void;
  }
}
declare module 'vue/types/vue' {
  interface Vue {
    $toSlack: any;
    state: any;
    isMobile: boolean;
    momentLocale: string;
    renderComponent: boolean;
    moment: any;
    role: { READ: number; WRITE: number };
    globalTZOffset?: string;
    windowWidth: number;
    windowHeight: number;

    forceRerender(): void;
    formatDate(value: any, format?: string): string;
    momentWithOffset(value?: any): any;
    formatCurrency(value: any, digits: number): number | string;
    isFiatOrStable(symbol: string): boolean;
    isFiat(symbol: string): boolean;
    trimZeros(num: string): string;
    isLetter(str: string): boolean;
    checkAccess(action: string, actionType: RoleTypeAction): boolean;
    checkMerchant(action: string): boolean;
    openTx(network: string, tx: string): string;
    switchTheme(val: string): void;
    isDev(): boolean;
    // formatNumber(num: number): number | string;
    formatCurrencyByCryptoType(
      symbol: string,
      amount: number | string,
      characters?: boolean
    ): number | string;
  }
}
interface MixinComponent extends Vue {
  moment: any;
}

Vue.mixin({
  data: () => ({
    moment: null as MixinComponent | null,
    renderComponent: true,
  }),
  directives: {
    tooltip: {
      bind: (el: any, binding: any) => {
        if (!binding.value) return;

        if (typeof binding.value === 'string' && binding.value.includes('.')) {
          const [integerPart, decimalPartWithSymbol] = binding.value.split('.');
          const [decimalPart, ...symbolParts] =
            decimalPartWithSymbol.split(' ');
          const symbol = symbolParts.join(' ');

          if (decimalPart.length > 8) {
            binding.value = `${integerPart}.${decimalPart.slice(
              0,
              8
            )} ${symbol}`;
          }
        }

        if (binding.modifiers?.positioned) {
          // appEl = document.getElementById('app');
          const rootMovingEl = document.getElementById('root-tooltip-wrapper');

          if (!rootMovingEl) return;

          // Function to add class on mouseenter
          const addClass = () => {
            if (!rootMovingEl.innerText.length) {
              const rect = el.getBoundingClientRect();
              rootMovingEl.classList.add('hovered');
              rootMovingEl.innerText = binding.value;

              const rectRME = rootMovingEl.getBoundingClientRect();
              rootMovingEl.style.position = 'fixed';
              rootMovingEl.style.top = `${rect.top + 40}px`;
              rootMovingEl.style.left = `${
                rect.left + window.scrollX + rect.width / 2 - rectRME.width / 2
              }px`;
            }
          };

          // Function to remove class on mouseleave
          const removeClass = () => {
            if (rootMovingEl.innerText.length) {
              rootMovingEl.classList.remove('hovered');
              rootMovingEl.innerText = '';
            }
          };

          if (!el.__tooltipHandlers__) {
            // Adding event listeners
            el.addEventListener('mouseenter', addClass);
            el.addEventListener('mouseleave', removeClass);

            // Store the handlers on the element for later cleanup
            el.__tooltipHandlers__ = { addClass, removeClass };
          }
        } else if (binding.modifiers?.positionedTop) {
          // appEl = document.getElementById('app');
          const rootMovingEl = document.getElementById('root-tooltip-wrapper');

          if (!rootMovingEl) return;

          // Function to add class on mouseenter
          const addClass = () => {
            if (!rootMovingEl.innerText.length) {
              const rect = el.getBoundingClientRect();
              rootMovingEl.classList.add('hovered');
              rootMovingEl.innerText = binding.value;

              const rectRME = rootMovingEl.getBoundingClientRect();
              rootMovingEl.style.position = 'fixed';
              rootMovingEl.style.top = `${rect.top - 25}px`;
              rootMovingEl.style.left = `${
                rect.left + window.scrollX + rect.width / 2 - rectRME.width / 2
              }px`;
            }
          };

          // Function to remove class on mouseleave
          const removeClass = () => {
            if (rootMovingEl.innerText.length) {
              rootMovingEl.classList.remove('hovered');
              rootMovingEl.innerText = '';
            }
          };

          if (!el.__tooltipHandlers__) {
            // Adding event listeners
            el.addEventListener('mouseenter', addClass);
            el.addEventListener('mouseleave', removeClass);

            // Store the handlers on the element for later cleanup
            el.__tooltipHandlers__ = { addClass, removeClass };
          }
        } else if (binding.modifiers?.positionedTopRight) {
          // appEl = document.getElementById('app');
          const rootMovingEl = document.getElementById('root-tooltip-wrapper');

          if (!rootMovingEl) return;

          // Function to add class on mouseenter
          const addClass = () => {
            if (!rootMovingEl.innerText.length) {
              const rect = el.getBoundingClientRect();
              rootMovingEl.classList.add('hovered');
              rootMovingEl.innerText = binding.value;

              const rectRME = rootMovingEl.getBoundingClientRect();
              rootMovingEl.style.position = 'fixed';
              rootMovingEl.style.top = `${rect.top - 25}px`;
              rootMovingEl.style.left = `${
                rect.left + window.scrollX + rect.width / 2 - rectRME.width
              }px`;
            }
          };

          // Function to remove class on mouseleave
          const removeClass = () => {
            if (rootMovingEl.innerText.length) {
              rootMovingEl.classList.remove('hovered');
              rootMovingEl.innerText = '';
            }
          };

          if (!el.__tooltipHandlers__) {
            // Adding event listeners
            el.addEventListener('mouseenter', addClass);
            el.addEventListener('mouseleave', removeClass);

            // Store the handlers on the element for later cleanup
            el.__tooltipHandlers__ = { addClass, removeClass };
          }
        } else {
          el.classList.add('tooltip-wrapper');
          el.insertAdjacentHTML(
            'afterbegin',
            `<div class="tooltip-text tooltip-center tooltip-position-${
              Object.keys(binding.modifiers)[0] || 'top'
            }">${binding.value}</div>`
          );
        }
      },
      unbind: (el: any) => {
        // Remove event listeners when the directive is unbound
        if (el.__tooltipHandlers__) {
          el.removeEventListener('mouseenter', el.__tooltipHandlers__.addClass);
          el.removeEventListener(
            'mouseleave',
            el.__tooltipHandlers__.removeClass
          );
          delete el.__tooltipHandlers__;
        }
      },
    },
    movingTooltip: {
      bind: (el: any, binding: any) => {
        if (!binding.value) return;

        // const id = `tt-${Number(Math.random() * 10000).toFixed(0)}`;
        el.classList.add('moving-tooltip-wrapper');
        // el.id = id;
        el.insertAdjacentHTML(
          'afterbegin',
          `<div class="tooltip-text">${binding.value}</div>`
        );

        el.addEventListener('mousemove', handleMouseMove(el));
      },
      unbind: (el: any) => {
        document.onmousemove = null;
      },
      update: (el: HTMLElement, binding: any) => {
        if (!binding.value) {
          document.onmousemove = null;
          el.querySelector('.tooltip-text')?.remove();
          el.classList.remove('moving-tooltip-wrapper');
        }
      },
    },
  },
  created() {
    (this as MixinComponent).moment = moment;
  },
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: window.location.href,
        },
      ],
    };
  },
  computed: {
    windowWidth: () => window.innerWidth,
    windowHeight: () => window.innerHeight,
    role: () => ({
      READ: 0,
      WRITE: 1,
    }),
    store: () => store,
    state: () => store.getters,
    console: () => console,
    isMobile() {
      return (this.$vuetify as Framework).breakpoint.smAndDown;
    },
    momentLocale() {
      const { state }: any = this;
      if (state.currentLang === 'kr') return 'ko';
      if (state.currentLang === 'cn') return 'zh-cn';
      return state.currentLang;
    },
    globalTZOffset: () => StorageHelper.get('timeZoneValue') || '+00:00',
    isUserAuth: () =>
      !!(store.state.user && Object.keys(store.state.user.userData)?.length),
  },
  methods: {
    isDev() {
      return process.env.VUE_APP_ENVIRONMENT === 'development';
    },
    labelClass(status: string) {
      if (['completed', 'paid', 'success'].includes(status.toLowerCase()))
        return 'success-label';

      if (['created', 'pending', 'processing'].includes(status.toLowerCase()))
        return 'pending-label';

      if (['overpaid', 'exchanging'].includes(status.toLowerCase()))
        return 'outstanding-label';

      if (
        ['partially_refunded', 'refunded', 'refunding'].includes(
          status.toLowerCase()
        )
      )
        return 'refunding-label';

      if (
        [
          'incompleted',
          'failed',
          'upload_failed',
          'exchange_failed',
          'underpaid',
        ].includes(status.toLowerCase())
      )
        return 'problematic-label';

      if (
        ![
          'completed',
          'paid',
          'success',
          'incompleted',
          'failed',
          'upload_failed',
          'exchange_failed',
          'expired',
        ].includes(status.toLowerCase())
      )
        return 'default-label';
    },
    back() {
      this.$router.back();
    },
    async copyText(text, withoutNotification = false) {
      await TextHelper.copyText(text);
      if (!withoutNotification) notify(this, 'success', 'Copied');
    },
    showNotification(type: string, message: string) {
      notify(this, type, message);
    },
    switchTheme(val: string) {
      this.$vuetify.theme.dark = val === 'dark';

      StorageHelper.set('nightMode', val);
    },
    checkAccess(action: string, actionType: RoleTypeAction) {
      return action
        ? checkAccess(this, action.toUpperCase(), actionType)
        : false;
    },
    checkMerchant(action: string) {
      return action ? checkMerchant(this, action.toUpperCase()) : true;
    },
    isLetter(str) {
      if (str?.length === 1 && str.match(/[a-z]/i)) {
        return true;
      }

      return false;
    },
    trimZeros(num: string) {
      const trimmedStr = num.replace(/0+$/, '');

      if (trimmedStr?.length === 0) {
        return '00';
      }

      return trimmedStr;
    },
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;
    },
    formatCurrency(value, digits = 8) {
      if (value === '' || !value) {
        return '0.00';
      }

      const formattedOriginal = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 20,
      });

      const formattedValue = trauncateFractionAndFormat(
        formattedOriginal.formatToParts(value),
        digits
      );

      const digitsAfterPoint = formattedValue.split('.');
      if (digitsAfterPoint?.length > 1) {
        const trimedDigits = this.trimZeros(digitsAfterPoint[1]);
        digitsAfterPoint[1] = trimedDigits;
        return digitsAfterPoint.join('.');
      }

      return formattedValue;
    },
    formatDate(value, format = 'DD-MM-YYYY', isOffset = true) {
      if (value === '' || !value) return '';

      let parsedMoment = (this as MixinComponent).moment(
        value,
        'YYYY-MM-DD HH:mm:ss',
        true
      );

      if (!parsedMoment.isValid())
        parsedMoment = (this as MixinComponent).moment(
          value,
          'DD-MM-YYYY HH:mm:ss',
          true
        );

      if (!parsedMoment.isValid())
        parsedMoment = (this as MixinComponent).moment(value);

      if (!parsedMoment.isValid()) return '';

      if (isOffset)
        return parsedMoment.utcOffset(this.globalTZOffset).format(format);

      return parsedMoment.format(format);
    },
    momentWithOffset(value) {
      const self: any = this;
      if (value) return moment(value).utcOffset(self.globalTZOffset);

      return moment().utcOffset(self.globalTZOffset);
    },
    isFiatOrStable(symbol: string) {
      if (symbol) {
        return currenciesList([...fiatList, ...stablecoinsList]).includes(
          symbol.toUpperCase()
        );
      }
      return false;
    },
    isFiat(symbol: string) {
      if (symbol) {
        return currenciesList([...fiatList]).includes(symbol.toUpperCase());
      }
      return false;
    },
    formatCurrencyByCryptoType(
      symbol: string,
      amount: number | string,
      characters?: boolean
    ) {
      const parsedAmount = Number(amount);

      if (characters) {
        return this.isFiat(symbol) ||
          currenciesList(['USDC', 'USDT']).includes(symbol)
          ? parsedAmount.toString().match(/^-?\d+(?:\.\d{0,2})?/)![0]
          : parsedAmount.toString().match(/^-?\d+(?:\.\d{0,8})?/)![0];
      }

      return this.isFiat(symbol) ||
        currenciesList(['USDC', 'USDT']).includes(symbol)
        ? this.formatCurrency(parsedAmount, 2)
        : this.formatCurrency(parsedAmount, 8);
    },
    async getImageUrl(imageName) {
      try {
        const { default: resultImage } = await import(
          `../assets/images/${imageName}`
        );
        return resultImage;
      } catch (e) {
        return Logo;
      }
    },
    openTx(network, tx) {
      let link = null;
      if (!tx) return '';

      if (network.includes('BNB') || network.includes('BSC')) {
        link = `https://bscscan.com/tx/${tx}`;
      } else if (network.includes('TRX')) {
        link = `https://tronscan.org/#/transaction/${tx}`;
      } else if (network.includes('XRP')) {
        link = `https://xrpscan.com/ledger/${tx.toUpperCase()}`;
      } else if (network.includes('ETH')) {
        link = `https://etherscan.io/tx/${tx}`;
      } else if (network.includes('BTC')) {
        link = `https://www.blockchain.com/btc/tx/${tx}`;
      } else if (network.includes('LTC') || network.includes('DOGE')) {
        link = `https://blockchair.com/litecoin/transaction/${tx}`;
      } else if (network.includes('MATIC')) {
        link = `https://polygonscan.com/address/${tx}`;
      } else {
        return;
      }

      if (link) {
        window.open(link);
      }
    },
    async getFileUrl(filePath) {
      try {
        const { default: resultFile } = await import(`../assets/${filePath}`);
        return resultFile;
      } catch (e) {
        if (process.env.VUE_APP_ENVIRONMENT === 'development') console.error(e);
      }
    },
  },
});
