<template>
  <v-dialog
    v-model="dialog"
    :max-width="isMobile ? '100%' : '420'"
    height="auto"
    :contentClass="`dialog-container custom-popup`"
  >
    <div class="popup-card">
      <v-btn
        icon
        :small="$vuetify.breakpoint.smAndDown"
        class="close"
        @click="close()"
      >
        <v-icon size="13">$close</v-icon>
      </v-btn>
      <div class="text-center">
        <h2 class="mt-2 mb-5">
          A Little ✨
          <br />
          Makeover Magic
        </h2>
        <p>
          Your portal now has a refreshed design! Same great features, with a
          stylish new twist. Dive in and explore!
        </p>
        <v-btn
          @click="submit()"
          rounded
          depressed
          class="v2-btn text-lowercase mt-9 px-10 py-3"
        >
          <span class="first-letter-capitalize">Explore now</span>
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<style lang="scss" scoped></style>

<script>
  import StorageHelper from '@/helpers/localStorageHelper';

  export default {
    name: 'GreetingsPopup',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({}),

    methods: {
      close() {
        this.dialog = false;
      },
      submit() {
        this.dialog = false;
        StorageHelper.set('greetingPopup', true);
      },
    },
    computed: {
      dialog: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
  };
</script>
