<template>
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="a"
      d="M27.837 20.9909L34.7535 27.9074M34.7535 27.9074L27.837 34.8239M34.7535 27.9074H2.47656M9.39306 16.3799L2.47656 9.46337M2.47656 9.46337L9.39306 2.54688M2.47656 9.46337H34.7535"
      stroke="#191919"
      stroke-width="4.611"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'SwapHorizontal',
  };
</script>

<style lang="scss" scoped>
  .a {
    stroke: var(--v-btn4color-base) !important;
  }
</style>
