<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="a"
      d="M3.20312 3.5V13.3333C3.20312 14.2668 3.20312 14.7331 3.39386 15.0897C3.56164 15.4033 3.82916 15.6587 4.15845 15.8185C4.53243 16 5.02224 16 6.00042 16H10.9058C11.884 16 12.3731 16 12.7471 15.8185C13.0764 15.6587 13.3448 15.4033 13.5126 15.0897C13.7031 14.7335 13.7031 14.2675 13.7031 13.3359V3.5M3.20312 3.5H4.95312M3.20312 3.5H1.45312M4.95312 3.5H11.9531M4.95312 3.5C4.95312 2.72343 4.95312 2.33534 5.08634 2.02905C5.26395 1.62067 5.6044 1.29602 6.0332 1.12687C6.3548 1 6.76273 1 7.57812 1H9.32812C10.1435 1 10.5512 1 10.8728 1.12687C11.3016 1.29602 11.6422 1.62067 11.8198 2.02905C11.953 2.33534 11.9531 2.72343 11.9531 3.5M11.9531 3.5H13.7031M13.7031 3.5H15.4531"
      stroke="#222122"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Trash',
  };
</script>

<style lang="scss" scoped>
  .a {
    color: currentColor;
  }
</style>
