<template>
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.2617 9.42185L5.26173 5.42189M5.26173 5.42189L1.26172 1.42188M5.26173 5.42189L9.26174 1.42188M5.26173 5.42189L1.26172 9.4219"
      stroke="#222122"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Close',
  };
</script>
