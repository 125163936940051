<template>
  <svg
    width="57"
    height="57"
    viewBox="0 0 57 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2617 47.0859H42.2617"
      class="a"
      stroke="#705CEB"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M28.2604 9.75781V37.7578M28.2604 37.7578L39.9271 26.0911M28.2604 37.7578L16.5938 26.0911"
      stroke="#705CEB"
      class="a"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'DownloadHuge',
  };
</script>

<style lang="scss" scoped>
  .a {
    stroke: currentColor;
  }
</style>
