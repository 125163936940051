<template>
  <svg
    width="27"
    height="18"
    viewBox="0 0 27 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="a"
      d="M2.249 11.3718C4.62865 13.7305 8.77872 17 13.5003 17C18.2219 17 22.3711 13.7305 24.7508 11.3718C25.3784 10.7498 25.6932 10.4377 25.8931 9.82688C26.0356 9.3911 26.0356 8.60903 25.8931 8.17325C25.6932 7.56246 25.3784 7.25031 24.7508 6.62818C22.3711 4.26947 18.2219 1 13.5003 1C8.77872 1 4.62865 4.26947 2.249 6.62818C1.62092 7.25074 1.30685 7.56224 1.10694 8.17325C0.964355 8.60903 0.964354 9.3911 1.10694 9.82688C1.30685 10.4379 1.62092 10.7493 2.249 11.3718Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      class="a"
      d="M10.8254 9.00006C10.8254 10.4728 12.0229 11.6667 13.5 11.6667C14.9772 11.6667 16.1747 10.4728 16.1747 9.00006C16.1747 7.52729 14.9772 6.33337 13.5 6.33337C12.0229 6.33337 10.8254 7.52729 10.8254 9.00006Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Eye',
  };
</script>

<style lang="scss" scoped>
  .a {
    stroke: #7f8fa6;
  }
</style>
