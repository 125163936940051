<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="a"
      d="M20.9414 11.7923H30.1634V2.57031"
      stroke="#858585"
      stroke-width="3.6888"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      class="a"
      d="M25.7481 25.9512C23.859 27.8418 21.4516 29.1297 18.8304 29.6519C16.2093 30.1741 13.4921 29.9072 11.0227 28.8849C8.55328 27.8627 6.44252 26.1309 4.95744 23.9089C3.47236 21.6868 2.67969 19.0741 2.67969 16.4015C2.67969 13.7288 3.47236 11.1162 4.95744 8.89411C6.44252 6.67202 8.55328 4.94031 11.0227 3.91803C13.4921 2.89575 16.2093 2.62883 18.8304 3.15105C21.4516 3.67326 23.859 4.96114 25.7481 6.85177L29.0128 10.6357"
      stroke="#858585"
      stroke-width="3.6888"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'Refresh',
  };
</script>

<style lang="scss" scoped>
  .a {
    stroke: currentColor;
  }
</style>
