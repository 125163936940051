<template>
  <svg
    width="27"
    height="23"
    viewBox="0 0 27 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="a"
      d="M2.80142 1L24.1982 22M19.5176 17.7421C17.7088 18.6984 15.6643 19.3748 13.4998 19.3748C8.77826 19.3748 4.62863 16.1565 2.249 13.8347C1.62087 13.2218 1.30685 12.9154 1.10694 12.3139C0.964375 11.8849 0.964331 11.1149 1.10694 10.6859C1.30691 10.0844 1.62158 9.77728 2.25096 9.16331C3.45036 7.99327 5.09905 6.59597 7.04372 5.49762M23.5295 14.9564C23.9749 14.5719 24.3828 14.1937 24.7488 13.8366L24.7527 13.8326C25.3794 13.2212 25.6936 12.9147 25.8932 12.3145C26.0358 11.8856 26.0355 11.1152 25.8929 10.6862C25.6931 10.085 25.3787 9.77767 24.751 9.16521C22.3713 6.84336 18.2213 3.625 13.4998 3.625C13.0484 3.625 12.6023 3.65441 12.1625 3.70963M15.2689 13.4688C14.7974 13.8772 14.1781 14.125 13.4998 14.125C12.0226 14.125 10.8252 12.9497 10.8252 11.5C10.8252 10.7919 11.1109 10.1495 11.5753 9.67737"
      stroke="#222122"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
  export default {
    name: 'EyeClose',
  };
</script>

<style lang="scss" scoped>
  .a {
    stroke: #7f8fa6;
  }
</style>
